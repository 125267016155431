import { Archivo, ProcesoPhotoRequest } from "../../../models/Archivos";
import { EvaluacionOrReparacionComponenteDetallesTable, EvaluacionOrReparacionComponenteEtapa, EvaluacionOrReparacionComponenteEtapaInforme, EvaluacionOrReparacionComponenteTareaInforme } from "../../../models/EvaluacionesReparaciones";
import { OrdenProceso } from "../../../models/estatusGeneral/OrdenProceso";
import portadaMotorTraccion from "../../../assets/images/motoresTraccion/portadaMotorTraccion.jpg";
import { ColumnWithValue, getTableCellValueWithUnits, pdfElements } from "../../pdfElements";
import condicionConjuntoPlanetario from "../../../assets/images/motoresTraccion/5GEB25/condicionConjuntoPlanetario.jpg";
import controlDesgastePlanetarioEsquema from "../../../assets/images/motoresTraccion/5GEB25/controlDesgastePlanetarioEsquema.jpg";
import controlDimensionalFrame from "../../../assets/images/motoresTraccion/5GEB25/controlDimensionalFrame.jpg";
import controlDesgasteCoronaTuboTorque from "../../../assets/images/motoresTraccion/5GEB25/controlDesgasteCoronaTuboTorque.jpg";
import controlDesgasteCoronaTuboTorque2 from "../../../assets/images/motoresTraccion/5GEB25/controlDesgasteCoronaTuboTorque2.jpg";
import controlDesgasteCoronaTuboTorque3 from "../../../assets/images/motoresTraccion/5GEB25/controlDesgasteCoronaTuboTorque3.jpg";
import controlDesgastePinonSolar from "../../../assets/images/motoresTraccion/5GEB25/controlDesgastePiñónSolar.jpg";
import metrologiaCuboRuedas from "../../../assets/images/motoresTraccion/5GEB25/metrologiaCuboRuedas.jpg";
import metrologiaCuboRuedas2 from "../../../assets/images/motoresTraccion/5GEB25/metrologiaCuboRuedas2.jpg";
import esquemaMedidaAnilloPortaSello from "../../../assets/images/motoresTraccion/5GEB25/esquemaMedidasAnilloPortaSello.jpg";
// import diametrosAnilloFlinger from "../../../assets/images/motoresTraccion/5GEB25/diametrosAnilloFlinger.jpg";
// import alturaAnilloFlinger from "../../../assets/images/motoresTraccion/5GEB25/alturaAnilloFlinger.jpg";
// import zonasAMedirAnilloFlinger from "../../../assets/images/motoresTraccion/5GEB25/zonasAMedirAnilloFlinger.jpg";
import esquemasMedidasAnilloFlinger from "../../../assets/images/motoresTraccion/5GEB25/esquemaMedidasAnilloFlinger.jpg";

const informeEvaluacion5GEB25 = (ordenProceso: OrdenProceso, finalEtapa: EvaluacionOrReparacionComponenteEtapa, etapas: EvaluacionOrReparacionComponenteEtapaInforme[], fotos?: ProcesoPhotoRequest[], isPreview?: boolean, anexo?: Archivo) => {
    const informesElements = new pdfElements(ordenProceso, finalEtapa, etapas, fotos, anexo);
    const halfWidth = (informesElements.totalUsableWidth - informesElements.spaceBetweenElements) / 2;
    const getMedidasMethodInches = (tarea?: EvaluacionOrReparacionComponenteTareaInforme, defaultTable?: EvaluacionOrReparacionComponenteDetallesTable) => (columnsNameWithValue: ColumnWithValue[], targetColumnName: string, table?: EvaluacionOrReparacionComponenteDetallesTable, withoutUnits?: boolean) =>
        getTableCellValueWithUnits(columnsNameWithValue, targetColumnName, tarea?.applyElements.apply, defaultTable || table, '"', withoutUnits);
    const informeTitle = 'INFORME DE EVALUACIÓN\nMOTOR DE TRACCIÓN';
    const esquemasMedidasHeight = informesElements.cellsHeight * 9;
    const metrologiaPartesMecanicasFrameEtapa = etapas.find((etapa) => etapa.nombre === 'Metrología');
    informesElements.addFrontPage(informeTitle, portadaMotorTraccion);
    informesElements.addPage();
    const retiroMotorElectricoEtapa = etapas.find((etapa) => etapa.nombre === 'Retiro de motor eléctrico');
    informesElements.identificacionCliente([
        { name: 'WHEELMOTOR S/N', value: etapas.find((etapa) => etapa.nombre === 'Recepción')?.tareas.find((tarea) => tarea.nombre === 'Número de serie wheel motor')?.inputs?.find((input) => input.name === 'Wheel motor S/N')?.value || '' },
        { name: 'ESTATOR S/N', value: retiroMotorElectricoEtapa?.tareas.find((tarea) => tarea.nombre === 'Número de serie estator')?.inputs?.find((input) => input.name === 'Estator S/N')?.value || '' },
        { name: 'ROTOR S/N', value: retiroMotorElectricoEtapa?.tareas.find((tarea) => tarea.nombre === 'Número de serie piñón solar')?.inputs?.find((input) => input.name === 'Rotor S/N')?.value || '' },
        { name: 'PIÑÓN SOLAR S/N', value: etapas.find((etapa) => etapa.nombre === 'Retiro de piñón solar')?.tareas.find((tarea) => tarea.nombre === 'Número de serie piñón solar')?.inputs?.find((input) => input.name === 'Piñón solar S/N')?.value || '' },
    ]);
    informesElements.actividadesEvaluacionOrReparacion([
        'Desarme general de motor de tracción.',
        'Retiro y evaluación de frenos.',
        'Retiro de tapas de planetarios para revisar condiciones de engranaje.',
        'Medición de endplay de rodamientos de cubo de ruedas.',
        'Retiro de motor eléctrico del frame.',
        'Revisión y evaluación de transmisión mecánica.'
    ]);
    informesElements.componentesDañadosAndFaltantesList();

    // MEDICIÓN HOLGURA ENTRE ANILLO FLINGER Y PORTA SELLO
    const medicionHolguraAnilloFlingerPortaSelloTarea = metrologiaPartesMecanicasFrameEtapa?.tareas.find((tarea) => tarea.nombre === 'Medición holgura entre anillo flinger y porta sello');
    const holguraAnilloFlingerPortaSelloTable = medicionHolguraAnilloFlingerPortaSelloTarea?.tables?.find((table) => table.name === 'Holgura entre anillo flinger y porta sello');
    const medidasAnilloPortaSelloTable = medicionHolguraAnilloFlingerPortaSelloTarea?.tables?.find((table) => table.name === 'Medidas anillo porta sello');
    const getHolguraAnilloFlingerPortaSello = (columnsNameWithValue: ColumnWithValue[], targetColumnName: string, withoutUnits?: boolean): string => getTableCellValueWithUnits(columnsNameWithValue, targetColumnName, medicionHolguraAnilloFlingerPortaSelloTarea?.applyElements.apply, holguraAnilloFlingerPortaSelloTable, 'mm', withoutUnits);
    const getMedidasAnilloPortaSello = getMedidasMethodInches(medicionHolguraAnilloFlingerPortaSelloTarea, medidasAnilloPortaSelloTable);
    informesElements.addPage();
    informesElements.docSubtitleRectangle('MEDICIÓN HOLGURA ENTRE ANILLO FLINGER Y PORTA SELLO');
    informesElements.addTable(
        [
            {
                rows: [[{ text: 'Holgura entre anillo flinger y porta sello', bold: true }]],
                rowsColor: informesElements.titleCellsFillColor
            },
            {
                rows: [
                    [
                        { text: 'Ángulo medido', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Valor referencial', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Valor medido', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Resultado', bold: true, color: informesElements.cellsFillColor }
                    ],
                    [
                        { text: '0° A 90°', bold: true },
                        { text: `${getHolguraAnilloFlingerPortaSello([{ column: 'Ángulo medido', value: '0° A 90°' }], 'Límite inferior (mm)')} a ${getHolguraAnilloFlingerPortaSello([{ column: 'Ángulo medido', value: '0° A 90°' }], 'Límite superior (mm)')}` },
                        { text: getHolguraAnilloFlingerPortaSello([{ column: 'Ángulo medido', value: '0° A 90°' }], 'Valor medido') },
                        { text: getHolguraAnilloFlingerPortaSello([{ column: 'Ángulo medido', value: '0° A 90°' }], 'Resultado', true) },
                    ],
                    [
                        { text: '90° A 180°', bold: true },
                        { text: `${getHolguraAnilloFlingerPortaSello([{ column: 'Ángulo medido', value: '90° A 180°' }], 'Límite inferior (mm)')} a ${getHolguraAnilloFlingerPortaSello([{ column: 'Ángulo medido', value: '90° A 180°' }], 'Límite superior (mm)')}` },
                        { text: getHolguraAnilloFlingerPortaSello([{ column: 'Ángulo medido', value: '90° A 180°' }], 'Valor medido') },
                        { text: getHolguraAnilloFlingerPortaSello([{ column: 'Ángulo medido', value: '90° A 180°' }], 'Resultado', true) },
                    ],
                    [
                        { text: '180° A 270°', bold: true },
                        { text: `${getHolguraAnilloFlingerPortaSello([{ column: 'Ángulo medido', value: '180° A 270°' }], 'Límite inferior (mm)')} a ${getHolguraAnilloFlingerPortaSello([{ column: 'Ángulo medido', value: '180° A 270°' }], 'Límite superior (mm)')}` },
                        { text: getHolguraAnilloFlingerPortaSello([{ column: 'Ángulo medido', value: '180° A 270°' }], 'Valor medido') },
                        { text: getHolguraAnilloFlingerPortaSello([{ column: 'Ángulo medido', value: '180° A 270°' }], 'Resultado', true) },
                    ],
                    [
                        { text: '270° A 360°', bold: true },
                        { text: `${getHolguraAnilloFlingerPortaSello([{ column: 'Ángulo medido', value: '270° A 360°' }], 'Límite inferior (mm)')} a ${getHolguraAnilloFlingerPortaSello([{ column: 'Ángulo medido', value: '270° A 360°' }], 'Límite superior (mm)')}` },
                        { text: getHolguraAnilloFlingerPortaSello([{ column: 'Ángulo medido', value: '270° A 360°' }], 'Valor medido') },
                        { text: getHolguraAnilloFlingerPortaSello([{ column: 'Ángulo medido', value: '270° A 360°' }], 'Resultado', true) },
                    ],
                ]
            },
        ]
    );
    informesElements.addTable(
        [
            {
                rows: [[{ text: 'Medidas anillo porta sello', bold: true }]],
                rowsColor: informesElements.titleCellsFillColor
            },
            {
                rows: [
                    [
                        { text: 'Ángulo medido', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Valor referencial', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Valor medido', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Resultado', bold: true, color: informesElements.cellsFillColor }
                    ],
                    [
                        { text: 'A', bold: true },
                        { text: getMedidasAnilloPortaSello([{ column: 'Ángulo medido', value: 'A' }], 'Límite inferior (" pulgadas)') },
                        { text: getMedidasAnilloPortaSello([{ column: 'Ángulo medido', value: 'A' }], 'Valor medido') },
                        { text: getMedidasAnilloPortaSello([{ column: 'Ángulo medido', value: 'A' }], 'Resultado', undefined, true) },
                    ],
                    [
                        { text: 'B', bold: true },
                        { text: getMedidasAnilloPortaSello([{ column: 'Ángulo medido', value: 'B' }], 'Límite inferior (" pulgadas)') },
                        { text: getMedidasAnilloPortaSello([{ column: 'Ángulo medido', value: 'B' }], 'Valor medido') },
                        { text: getMedidasAnilloPortaSello([{ column: 'Ángulo medido', value: 'B' }], 'Resultado', undefined, true) },
                    ]
                    // [
                    //     { text: 'C', bold: true },
                    //     { text: getMedidasAnilloPortaSello([{ column: 'Ángulo medido', value: 'C' }], 'Límite inferior (mm)') },
                    //     { text: getMedidasAnilloPortaSello([{ column: 'Ángulo medido', value: 'C' }], 'Valor medido') },
                    //     { text: getMedidasAnilloPortaSello([{ column: 'Ángulo medido', value: 'C' }], 'Resultado', undefined, true) },
                    // ],
                    // [
                    //     { text: 'D', bold: true },
                    //     { text: getMedidasAnilloPortaSello([{ column: 'Ángulo medido', value: 'D' }], 'Límite inferior (mm)') },
                    //     { text: getMedidasAnilloPortaSello([{ column: 'Ángulo medido', value: 'D' }], 'Valor medido') },
                    //     { text: getMedidasAnilloPortaSello([{ column: 'Ángulo medido', value: 'D' }], 'Resultado', undefined, true) },
                    // ],
                    // [
                    //     { text: 'E', bold: true },
                    //     { text: getMedidasAnilloPortaSello([{ column: 'Ángulo medido', value: 'E' }], 'Límite inferior (mm)') },
                    //     { text: getMedidasAnilloPortaSello([{ column: 'Ángulo medido', value: 'E' }], 'Valor medido') },
                    //     { text: getMedidasAnilloPortaSello([{ column: 'Ángulo medido', value: 'E' }], 'Resultado', undefined, true) },
                    // ],
                ]
            },
        ]
    );
    const anilloFlingerImagesWidth = (informesElements.totalUsableWidth - (informesElements.margin * 2)) / 1.25;
    informesElements.addImage(
        esquemaMedidaAnilloPortaSello,
        esquemasMedidasHeight,
        anilloFlingerImagesWidth,
        undefined,
        undefined,
        true
    );
    // informesElements.addImageCenteredInTotalWidth(
    //     diametrosAnilloFlinger,
    //     anilloFlingerImagesHeight,
    //     anilloFlingerImagesWidth
    // );
    // informesElements.addImageCenteredInTotalWidth(
    //     alturaAnilloFlinger,
    //     anilloFlingerImagesHeight,
    //     anilloFlingerImagesWidth
    // );
    // informesElements.addImageCenteredInTotalWidth(
    //     zonasAMedirAnilloFlinger,
    //     anilloFlingerImagesHeight,
    //     (informesElements.totalUsableWidth - (informesElements.margin * 2)) / 4
    // );

    // PRUEBAS DE LÍNEAS DE FRENOS
    informesElements.addPage();
    informesElements.docSubtitleRectangle('PRUEBAS DE LÍNEAS DE FRENOS');
    const pruebaEstanqueidadCañeríasFrenosValuesTarea = etapas.find((etapa) => etapa.nombre === 'Prueba líneas de freno')?.tareas.find((tarea) => tarea.nombre === 'Prueba de estanqueidad a cañerías de frenos');
    const pruebaEstanqueidadCañeríasFrenosValuesTable = pruebaEstanqueidadCañeríasFrenosValuesTarea?.tables?.find((table) => table.name === '1');
    const getPruebaEstanqueidadCañeríasFrenosValues = (columnsNameWithValue: ColumnWithValue[], targetColumnName: string, withUnits?: boolean) =>
        getTableCellValueWithUnits(columnsNameWithValue, targetColumnName, pruebaEstanqueidadCañeríasFrenosValuesTarea?.applyElements.apply, pruebaEstanqueidadCañeríasFrenosValuesTable, withUnits ? 'PSI' : undefined);
    // const getPruebaEstanqueidadCañeríasFrenosValues = (columnsNameWithValue: ColumnWithValue[], targetColumnName: string, withUnits?: boolean) =>
    //     getTableCellValueWithUnits(columnsNameWithValue, targetColumnName, pruebaEstanqueidadCañeríasFrenosValuesTarea?.applyElements.apply, pruebaEstanqueidadCañeríasFrenosValuesTable, withUnits ? 'PSI' : undefined);
    let pruebaEstanqueidadCañeríasFrenosValuesPresion = getPruebaEstanqueidadCañeríasFrenosValues([{ column: 'N° Línea', value: '1'}], 'Presión de prueba (PSI)', true);
    if (pruebaEstanqueidadCañeríasFrenosValuesPresion === 'N/A') {
        pruebaEstanqueidadCañeríasFrenosValuesPresion = '2500 PSI';
    }
    informesElements.addParagraph(`Se revisa líneas de freno aplicando ${pruebaEstanqueidadCañeríasFrenosValuesPresion} de presión hidráulica por 1 min para detectar fisuras. Los resultados son:`);
    informesElements.addTable(
        [{
            rows: [
                [
                    { text: 'N° Línea', color: informesElements.titleCellsFillColor, bold: true },
                    { text: 'Presión de prueba', color: informesElements.titleCellsFillColor, bold: true },
                    { text: 'Resultado', color: informesElements.titleCellsFillColor, bold: true },
                ],
                [
                    { text: '1', bold: true },
                    { text: pruebaEstanqueidadCañeríasFrenosValuesPresion },
                    { text: getPruebaEstanqueidadCañeríasFrenosValues([{ column: 'N° Línea', value: '1' }], 'Resultado') },
                ],
                [
                    { text: '2', bold: true },
                    { text: pruebaEstanqueidadCañeríasFrenosValuesPresion },
                    { text: getPruebaEstanqueidadCañeríasFrenosValues([{ column: 'N° Línea', value: '2' }], 'Resultado') },
                ],
                [
                    { text: '3', bold: true },
                    { text: pruebaEstanqueidadCañeríasFrenosValuesPresion },
                    { text: getPruebaEstanqueidadCañeríasFrenosValues([{ column: 'N° Línea', value: '3' }], 'Resultado') },
                ],
                [
                    { text: '4', bold: true },
                    { text: pruebaEstanqueidadCañeríasFrenosValuesPresion },
                    { text: getPruebaEstanqueidadCañeríasFrenosValues([{ column: 'N° Línea', value: '4' }], 'Resultado') },
                ],
            ]
        }]
    );
    informesElements.rectangleObs(
        'Observaciones pruebas de líneas de frenos',
        pruebaEstanqueidadCañeríasFrenosValuesTarea?.observations ? [pruebaEstanqueidadCañeríasFrenosValuesTarea?.observations] : undefined,
        false,
        informesElements.cellsHeight * 6
    );

    // JUEGO AXIAL RODAMIENTO CUBO DE RUEDA
    informesElements.docSubtitleRectangle('JUEGO AXIAL RODAMIENTO CUBO DE RUEDA');
    const medirJuegoAxialCuboRuedaTarea = etapas.find((etapa) => etapa.nombre === 'Desarme inicial')?.tareas.find((tarea) => tarea.nombre === 'Medir juego axial de cubo de rueda');
    const cantidadLainasTable = medirJuegoAxialCuboRuedaTarea?.tables?.find((table) => table.name === 'Cantidad de lainas');
    if (cantidadLainasTable) {
        informesElements.addTable(
            [{
                rows: [
                    [
                        { text: 'Posición', color: informesElements.titleCellsFillColor, bold: true },
                        { text: 'N° lainas 0.005"', color: informesElements.titleCellsFillColor, bold: true },
                        { text: 'N° lainas 0.007"', color: informesElements.titleCellsFillColor, bold: true },
                        { text: 'N° lainas 0.030"', color: informesElements.titleCellsFillColor, bold: true },
                        { text: 'Suma de longitudes', color: informesElements.titleCellsFillColor, bold: true }
                    ],
                    ...cantidadLainasTable.rows.map((row) => ([
                        { text: row[0].content[0], color: informesElements.cellsFillColor, bold: true },
                        { text: (medirJuegoAxialCuboRuedaTarea?.applyElements.apply && row[0].apply !== false) ? row[1].content[0] || '0' : 'N/A' },
                        { text: (medirJuegoAxialCuboRuedaTarea?.applyElements.apply && row[0].apply !== false) ? row[2].content[0] || '0' : 'N/A' },
                        { text: (medirJuegoAxialCuboRuedaTarea?.applyElements.apply && row[0].apply !== false) ? row[3].content[0] || '0' : 'N/A' },
                        { text: (medirJuegoAxialCuboRuedaTarea?.applyElements.apply && row[0].apply !== false) ? row[4].content[0] || '0' : 'N/A' }
                    ]))
                ]
            }]  
        );
    }
    // informesElements.addTable([{
    //     rows: [
    //         [
    //             { text: 'Cantidad de lainas', color: informesElements.titleCellsFillColor, bold: true },
    //             { text: ((): string => {
    //                 const table = medirJuegoAxialCuboRuedaTarea?.tables?.find((table) => table.name === 'Cantidad de lainas');
    //                 let response = 0;
    //                 if (table) {
    //                     table.rows.forEach((row) => {
    //                         for (let i = 1; i < 4; i++) {
    //                             response = response + parseFloat(row[i].content[0] || '0');
    //                         }
    //                     })
    //                 }
    //                 return `${response}`;
    //             })() }
    //         ]
    //     ]
    // }]);
    const medirJuegoAxialCuboRuedaTable = medirJuegoAxialCuboRuedaTarea?.tables?.find((table) => table.name === 'Mediciones axial cubo de rueda');
    const axialCuboRuedaLimiteInferior = getTableCellValueWithUnits([{ column: 'Elemento', value: 'Posición A' }], 'Límite inferior (" pulgadas)', true, medirJuegoAxialCuboRuedaTable, undefined, true);
    const axialCuboRuedaLimiteSuperior = getTableCellValueWithUnits([{ column: 'Elemento', value: 'Posición A' }], 'Límite superior (" pulgadas)', true, medirJuegoAxialCuboRuedaTable, undefined, true);
    informesElements.addTable([
        {
            rows: [
                [
                    { text: 'Posición\nAxial cubo de ruedas', bold: true },
                    {
                        text: `Valor\n(Rangos: ${axialCuboRuedaLimiteInferior === 'N/A' ? '0.015' : axialCuboRuedaLimiteInferior}” min - ${axialCuboRuedaLimiteSuperior === 'N/A' ? '0.02' : axialCuboRuedaLimiteSuperior}” max)`,
                        bold: true
                    },
                    { text: 'Resultado', bold: true },
                ]
            ],
            rowCellsHeightInUnits: 2,
            rowsColor: informesElements.titleCellsFillColor
        },
        {
            rows: [
                [
                    { text: 'Posición A', bold: true, color: informesElements.cellsFillColor },
                    { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Posición A' }], 'Valor (" pulgadas)', medirJuegoAxialCuboRuedaTarea?.applyElements.apply, medirJuegoAxialCuboRuedaTable, '"') },
                    { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Posición A' }], 'Resultado', medirJuegoAxialCuboRuedaTarea?.applyElements.apply, medirJuegoAxialCuboRuedaTable) }
                ],
                [
                    { text: 'Posición B', bold: true, color: informesElements.cellsFillColor },
                    { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Posición B' }], 'Valor (" pulgadas)', medirJuegoAxialCuboRuedaTarea?.applyElements.apply, medirJuegoAxialCuboRuedaTable, '"') },
                    { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Posición B' }], 'Resultado', medirJuegoAxialCuboRuedaTarea?.applyElements.apply, medirJuegoAxialCuboRuedaTable) }
                ],
                [
                    { text: 'Posición C', bold: true, color: informesElements.cellsFillColor },
                    { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Posición C' }], 'Valor (" pulgadas)', medirJuegoAxialCuboRuedaTarea?.applyElements.apply, medirJuegoAxialCuboRuedaTable, '"') },
                    { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Posición C' }], 'Resultado', medirJuegoAxialCuboRuedaTarea?.applyElements.apply, medirJuegoAxialCuboRuedaTable) }
                ],
                [
                    { text: 'Posición D', bold: true, color: informesElements.cellsFillColor },
                    { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Posición D' }], 'Valor (" pulgadas)', medirJuegoAxialCuboRuedaTarea?.applyElements.apply, medirJuegoAxialCuboRuedaTable, '"') },
                    { text: getTableCellValueWithUnits([{ column: 'Elemento', value: 'Posición D' }], 'Resultado', medirJuegoAxialCuboRuedaTarea?.applyElements.apply, medirJuegoAxialCuboRuedaTable) }
                ],
            ]
        }
    ]);
    informesElements.rectangleObs(
        'Observaciones juego axial rodamientos cubo de rueda',
        medirJuegoAxialCuboRuedaTarea?.observations ? [medirJuegoAxialCuboRuedaTarea?.observations] : undefined
    );

    // CONDICIÓN DE CONJUNTO PLANETARIO DURANTE EVALUACIÓN
    informesElements.addPage();
    informesElements.docSubtitleRectangle('CONDICIÓN DE CONJUNTO PLANETARIO DURANTE EVALUACIÓN');
    const metrologiaPlanetariosATarea = metrologiaPartesMecanicasFrameEtapa?.tareas.find((tarea) => tarea.nombre === 'Metrología planetarios A');
    const metrologiaPlanetariosBTarea = metrologiaPartesMecanicasFrameEtapa?.tareas.find((tarea) => tarea.nombre === 'Metrología planetarios B');
    const metrologiaPlanetariosCTarea = metrologiaPartesMecanicasFrameEtapa?.tareas.find((tarea) => tarea.nombre === 'Metrología planetarios C');
    const medicionBacklashPlanetariosTarea = etapas.find((etapa) => etapa.nombre === 'Desarme inicial')?.tareas.find((tarea) => tarea.nombre === 'Medir juego backlash planetarios');
    const medicionBacklashPlanetariosTable = medicionBacklashPlanetariosTarea?.tables?.find((table) => table.name === 'Backlash');
    const medicionDiferenciasBacklashPlanetariosTable = medicionBacklashPlanetariosTarea?.tables?.find((table) => table.name === 'Diferencias de backlash');
    if (medicionBacklashPlanetariosTable && medicionDiferenciasBacklashPlanetariosTable) {
        informesElements.addTable(
            [{
                rows: [
                    [
                        { text: 'Backlash', color: informesElements.titleCellsFillColor, bold: true },
                        { text: 'Valor', color: informesElements.titleCellsFillColor, bold: true },
                        { text: 'Resultado', color: informesElements.titleCellsFillColor, bold: true }
                    ],
                    ...medicionBacklashPlanetariosTable.rows.map((row) => ([
                        { text: row[0].content[0] || '', bold: true, color: informesElements.cellsFillColor },
                        { text: row[0].apply !== false ? (row[1].content[0] ? `${row[1].content[0]} "` : 'N/A') : 'N/A' },
                        { text: 'N/A' }
                    ])),
                    ...medicionDiferenciasBacklashPlanetariosTable.rows.map((row) => ([
                        { text: row[0].content[0] || '', bold: true, color: informesElements.cellsFillColor },
                        { text: row[0].apply !== false ? (row[1].content[0] ? `${row[1].content[0]} "` : 'N/A') : 'N/A' },
                        { text: row[0].apply !== false ? (row[4].content[0] ? `${row[4].content[0]}` : 'N/A') : 'N/A' }
                    ]))
                ]
            }]
        );
    }
    informesElements.rectangleObs(
        'Observaciones conjunto de planetarios',
        medicionBacklashPlanetariosTarea?.observations ? [medicionBacklashPlanetariosTarea?.observations] : undefined,
        false,
        informesElements.cellsHeight * 10
    );
    informesElements.addTable(
        [{
            rows: [
                [
                    { text: 'Planetario', color: informesElements.titleCellsFillColor, bold: true },
                    { text: 'N/S Eje', color: informesElements.titleCellsFillColor, bold: true },
                    { text: 'N/S Corona', color: informesElements.titleCellsFillColor, bold: true },
                ],
                [
                    { text: 'A', bold: true, color: informesElements.cellsFillColor },
                    { text: metrologiaPlanetariosATarea?.inputs?.find((input) => input.name === 'Eje N/S')?.value || 'N/A' },
                    { text: metrologiaPlanetariosATarea?.inputs?.find((input) => input.name === 'Corona N/S')?.value || 'N/A' }
                ],
                [
                    { text: 'B', bold: true, color: informesElements.cellsFillColor },
                    { text: metrologiaPlanetariosBTarea?.inputs?.find((input) => input.name === 'Eje N/S')?.value || 'N/A' },
                    { text: metrologiaPlanetariosBTarea?.inputs?.find((input) => input.name === 'Corona N/S')?.value || 'N/A' }
                ],
                [
                    { text: 'C', bold: true, color: informesElements.cellsFillColor },
                    { text: metrologiaPlanetariosCTarea?.inputs?.find((input) => input.name === 'Eje N/S')?.value || 'N/A' },
                    { text: metrologiaPlanetariosCTarea?.inputs?.find((input) => input.name === 'Corona N/S')?.value || 'N/A' }
                ],
            ]
        }]
    );
    const condicionConjuntoPlanetarioImageWidth = informesElements.totalUsableWidth / 2.5;
    informesElements.addImage(
        condicionConjuntoPlanetario,
        informesElements.cellsHeight * 8,
        condicionConjuntoPlanetarioImageWidth,
        undefined,
        undefined,
        true
    );
    
    // CONTROL DESGASTE DE PLANETARIOS
    informesElements.addPage();
    informesElements.docSubtitleRectangle('CONTROL DESGASTE DE PLANETARIOS');
    
    const controlDesgastePlanetarioPage = (planetario: string, skipAddPage?: boolean) => {
        const planetarioLetterUpperCase = planetario.toUpperCase();
        const metrologiaPlanetarioTarea = planetario === 'A' ? metrologiaPlanetariosATarea : planetario === 'B' ? metrologiaPlanetariosBTarea : metrologiaPlanetariosCTarea;
        const getMetrologiaPlanetarioTable = (tableName: string) => metrologiaPlanetarioTarea?.tables?.find((table) => table.name === tableName);
        const coronaPlanetario = getMetrologiaPlanetarioTable('Metrología corona planetario - PIN 0,800”');
        const piñonPlanetario = getMetrologiaPlanetarioTable('Metrología piñón planetario - PIN 0,864”');
        const rodamientoRectoPlanetario = getMetrologiaPlanetarioTable('Metrología eje pista rodamiento recto');
        const rodamientoConicoA = getMetrologiaPlanetarioTable('Metrología eje pista rodamiento cónico pista A superior');
        const rodamientoConicoB = getMetrologiaPlanetarioTable('Metrología eje pista rodamiento cónico pista B inferior');
        const getPlanetarioMedidas = getMedidasMethodInches(metrologiaPlanetarioTarea);
        const rodamientoConicoAInferior = getPlanetarioMedidas([{ column: 'Medición', value: '0°' }], 'Límite inferior (" pulgadas)', rodamientoConicoA);
        const rodamientoConicoASuperior = getPlanetarioMedidas([{ column: 'Medición', value: '0°' }], 'Límite superior (" pulgadas)', rodamientoConicoA);
        const coronaInferior = getPlanetarioMedidas([{ column: 'Medición', value: '0°' }], 'Límite inferior (" pulgadas)', rodamientoConicoA);
        const coronaSuperior = getPlanetarioMedidas([{ column: 'Medición', value: '0°' }], 'Límite superior (" pulgadas)', rodamientoConicoA);
        const piñonInferior = getPlanetarioMedidas([{ column: 'Medición', value: '0°' }], 'Límite inferior (" pulgadas)', rodamientoConicoA);
        const piñonSuperior = getPlanetarioMedidas([{ column: 'Medición', value: '0°' }], 'Límite superior (" pulgadas)', rodamientoConicoA);
        const rodamientoRectoInferior = getPlanetarioMedidas([{ column: 'Medición', value: '0°' }], 'Límite inferior (" pulgadas)', rodamientoConicoA);
        const rodamientoRectoSuperior = getPlanetarioMedidas([{ column: 'Medición', value: '0°' }], 'Límite superior (" pulgadas)', rodamientoConicoA);
        if (!skipAddPage) {
            informesElements.addPage();
        }
        informesElements.docSubtitleRectangle(`CONTROL DESGASTE DE PLANETARIO ${planetarioLetterUpperCase}`, undefined, true);
        informesElements.addTable(
            [
                {
                    rows: [
                        [
                            {
                                text: `Pista de rodamiento cónicos\n(Rangos: ${rodamientoConicoAInferior === 'N/A' ? '3,3495' : rodamientoConicoAInferior}" min - ${rodamientoConicoASuperior === 'N/A' ? '3,3486' : rodamientoConicoASuperior}" max)`,
                                bold: true,
                                // color: informesElements.titleCellsFillColor,
                                // cellsWidthInUnits: 8
                            }
                        ]
                    ],
                    rowCellsHeightInUnits: 2,
                    rowsColor: informesElements.titleCellsFillColor,
                    // totalCellsWidthInUnits: 8
                },
                {
                    rows: [
                        [
                            { text: 'Pista Ø A Superior', bold: true, cellsWidthInUnits: 4 },
                            { text: 'Pista Ø B Inferior', bold: true, cellsWidthInUnits: 4 }
                        ]
                    ],
                    rowsColor: informesElements.cellsFillColor,
                    totalCellsWidthInUnits: 8
                },
                {
                    rows: [
                        [
                            { text: 'Grados', bold: true, color: informesElements.cellsFillColor },
                            { text: 'Medida A', bold: true, color: informesElements.cellsFillColor },
                            { text: 'Medida B', bold: true, color: informesElements.cellsFillColor },
                            { text: 'Medida C', bold: true, color: informesElements.cellsFillColor },
                            { text: 'Grados', bold: true, color: informesElements.cellsFillColor },
                            { text: 'Medida A', bold: true, color: informesElements.cellsFillColor },
                            { text: 'Medida B', bold: true, color: informesElements.cellsFillColor },
                            { text: 'Medida C', bold: true, color: informesElements.cellsFillColor },
                        ],
                        [
                            { text: '0°', bold: true, color: informesElements.cellsFillColor },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], '0°', rodamientoConicoA) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], '0°', rodamientoConicoA) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], '0°', rodamientoConicoA) },
                            { text: '0°', bold: true, color: informesElements.cellsFillColor },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], '0°', rodamientoConicoB) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], '0°', rodamientoConicoB) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], '0°', rodamientoConicoB) },
                        ],
                        [
                            { text: '45°', bold: true, color: informesElements.cellsFillColor },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], '45°', rodamientoConicoA) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], '45°', rodamientoConicoA) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], '45°', rodamientoConicoA) },
                            { text: '45°', bold: true, color: informesElements.cellsFillColor },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], '45°', rodamientoConicoB) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], '45°', rodamientoConicoB) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], '45°', rodamientoConicoB) },
                        ],
                        [
                            { text: '90°', bold: true, color: informesElements.cellsFillColor },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], '90°', rodamientoConicoA) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], '90°', rodamientoConicoA) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], '90°', rodamientoConicoA) },
                            { text: '90°', bold: true, color: informesElements.cellsFillColor },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], '90°', rodamientoConicoB) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], '90°', rodamientoConicoB) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], '90°', rodamientoConicoB) },
                        ],
                    ],
                    // rowsColor: informesElements.cellsFillColor,
                    // totalCellsWidthInUnits: 8
                }
            ]
        );
        informesElements.addTable(
            [
                {
                    rows: [
                        [
                            {
                                text: `Desgaste de piñón\n(Rangos: ${piñonInferior === 'N/A' ? '8,7147' : piñonInferior}" min - ${piñonSuperior === 'N/A' ? '8,7191' : piñonSuperior}" max - PIN 0,864”)`,
                                bold: true,
                            }
                        ]
                    ],
                    rowCellsHeightInUnits: 2,
                    rowsColor: informesElements.titleCellsFillColor,
                },
                {
                    rows: [
                        [
                            { text: 'Grados', bold: true, color: informesElements.cellsFillColor },
                            { text: 'Medida A', bold: true, color: informesElements.cellsFillColor },
                            { text: 'Medida B', bold: true, color: informesElements.cellsFillColor },
                            { text: 'Medida C', bold: true, color: informesElements.cellsFillColor }
                        ],
                        [
                            { text: '0°', bold: true, color: informesElements.cellsFillColor },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], '0°', piñonPlanetario) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], '0°', piñonPlanetario) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], '0°', piñonPlanetario) }
                        ],
                        [
                            { text: '45°', bold: true, color: informesElements.cellsFillColor },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], '45°', piñonPlanetario) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], '45°', piñonPlanetario) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], '45°', piñonPlanetario) }
                        ],
                        [
                            { text: '90°', bold: true, color: informesElements.cellsFillColor },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], '90°', piñonPlanetario) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], '90°', piñonPlanetario) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], '90°', piñonPlanetario) }
                        ],
                        [
                            { text: '135°', bold: true, color: informesElements.cellsFillColor },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], '135°', piñonPlanetario) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], '135°', piñonPlanetario) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], '135°', piñonPlanetario) }
                        ],
                        [
                            { text: 'Promedio', bold: true, color: informesElements.cellsFillColor },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], 'Promedio', piñonPlanetario) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], 'Promedio', piñonPlanetario) },
                            { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], 'Promedio', piñonPlanetario) }
                        ]
                    ],
                    // rowsColor: informesElements.cellsFillColor,
                    // totalCellsWidthInUnits: 8
                }
            ],
            undefined,
            false,
            informesElements.addTable(
                [
                    {
                        rows: [
                            [
                                {
                                    text: `Desgaste de corona (Gear)\n(Rangos: ${coronaInferior === 'N/A' ? '29,8804' : coronaInferior}" min - ${coronaSuperior === 'N/A' ? '29,8884' : coronaSuperior}" max - PIN 0,800”)`,
                                    bold: true,
                                }
                            ]
                        ],
                        rowCellsHeightInUnits: 2,
                        rowsColor: informesElements.titleCellsFillColor,
                    },
                    {
                        rows: [
                            [
                                { text: 'Grados', bold: true, color: informesElements.cellsFillColor },
                                { text: 'Medida A', bold: true, color: informesElements.cellsFillColor },
                                { text: 'Medida B', bold: true, color: informesElements.cellsFillColor },
                                { text: 'Medida C', bold: true, color: informesElements.cellsFillColor }
                            ],
                            [
                                { text: '0°', bold: true, color: informesElements.cellsFillColor },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], '0°', coronaPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], '0°', coronaPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], '0°', coronaPlanetario) }
                            ],
                            [
                                { text: '45°', bold: true, color: informesElements.cellsFillColor },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], '45°', coronaPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], '45°', coronaPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], '45°', coronaPlanetario) }
                            ],
                            [
                                { text: '90°', bold: true, color: informesElements.cellsFillColor },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], '90°', coronaPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], '90°', coronaPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], '90°', coronaPlanetario) }
                            ],
                            [
                                { text: '135°', bold: true, color: informesElements.cellsFillColor },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], '135°', coronaPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], '135°', coronaPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], '135°', coronaPlanetario) }
                            ],
                            [
                                { text: 'Promedio', bold: true, color: informesElements.cellsFillColor },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], 'Promedio', coronaPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], 'Promedio', coronaPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], 'Promedio', coronaPlanetario) }
                            ]
                        ]
                    }
                ],
                halfWidth,
                true
            )
        );
        informesElements.addImage(
            controlDesgastePlanetarioEsquema,
            informesElements.cellsHeight * 13,
            halfWidth,
            informesElements.addTable(
                [
                    {
                        rows: [
                            [
                                {
                                    text: `Pista rodamiento recto\n(Rangos: ${rodamientoRectoInferior === 'N/A' ? '8,6632' : rodamientoRectoInferior}" min - ${rodamientoRectoSuperior === 'N/A' ? '8,6642' : rodamientoRectoSuperior}" max)`,
                                    bold: true,
                                }
                            ]
                        ],
                        rowCellsHeightInUnits: 2,
                        rowsColor: informesElements.titleCellsFillColor,
                    },
                    {
                        rows: [
                            [
                                { text: 'Grados', bold: true, color: informesElements.cellsFillColor },
                                { text: 'Medida A', bold: true, color: informesElements.cellsFillColor },
                                { text: 'Medida B', bold: true, color: informesElements.cellsFillColor },
                                { text: 'Medida C', bold: true, color: informesElements.cellsFillColor }
                            ],
                            [
                                { text: '0°', bold: true, color: informesElements.cellsFillColor },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], '0°', rodamientoRectoPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], '0°', rodamientoRectoPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], '0°', rodamientoRectoPlanetario) }
                            ],
                            [
                                { text: '45°', bold: true, color: informesElements.cellsFillColor },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], '45°', rodamientoRectoPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], '45°', rodamientoRectoPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], '45°', rodamientoRectoPlanetario) }
                            ],
                            [
                                { text: '90°', bold: true, color: informesElements.cellsFillColor },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], '90°', rodamientoRectoPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], '90°', rodamientoRectoPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], '90°', rodamientoRectoPlanetario) }
                            ],
                            [
                                { text: '135°', bold: true, color: informesElements.cellsFillColor },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], '135°', rodamientoRectoPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], '135°', rodamientoRectoPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], '135°', rodamientoRectoPlanetario) }
                            ],
                            [
                                { text: 'Promedio', bold: true, color: informesElements.cellsFillColor },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida A' }], 'Promedio', rodamientoRectoPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida B' }], 'Promedio', rodamientoRectoPlanetario) },
                                { text: getPlanetarioMedidas([{ column: 'Medición', value: 'Medida C' }], 'Promedio', rodamientoRectoPlanetario) }
                            ]
                        ]
                    }
                ],
                halfWidth,
                true
            )
        );
        informesElements.rectangleObs(
            `Observaciones desgaste de planetario ${planetarioLetterUpperCase}`,
            metrologiaPlanetarioTarea?.observations ? [metrologiaPlanetarioTarea?.observations] : undefined
        );
    };
    controlDesgastePlanetarioPage('A', true);
    controlDesgastePlanetarioPage('B');
    controlDesgastePlanetarioPage('C');

    // CONTROL DIMENSIONAL DE FRAME
    informesElements.addPage();
    informesElements.docSubtitleRectangle('CONTROL DIMENSIONAL DE FRAME');
    informesElements.addImage(
        controlDimensionalFrame,
        informesElements.cellsHeight * 11,
        informesElements.totalUsableWidth / 3,
        undefined,
        undefined,
        true
    );

    informesElements.docSubtitleRectangle('METROLOGÍA PISTA SUPERIOR E INFERIOR DE FRAME', undefined, true);
    const metrologiaFrameTarea = metrologiaPartesMecanicasFrameEtapa?.tareas.find((tarea) => tarea.nombre === 'Metrología de frame');
    const metrologiaFrameInferior = metrologiaFrameTarea?.tables?.find((table) => table.name === 'Metrología pista rodamiento inferior N° Rod: 8864953P119');
    const metrologiaFrameSuperior = metrologiaFrameTarea?.tables?.find((table) => table.name === 'Metrología pista rodamiento superior N° Rod: 8864953P120');
    const getFrameMedidas = getMedidasMethodInches(metrologiaFrameTarea);
    const metrologiaFrameInferiorLimInferior = getFrameMedidas([{ column: 'Medición', value: '0°' }], 'Límite inferior (" pulgadas)', metrologiaFrameInferior);
    const metrologiaFrameInferiorLimSuperior = getFrameMedidas([{ column: 'Medición', value: '0°' }], 'Límite superior (" pulgadas)', metrologiaFrameInferior);
    const metrologiaFrameSuperiorLimInferior = getFrameMedidas([{ column: 'Medición', value: '0°' }], 'Límite inferior (" pulgadas)', metrologiaFrameSuperior);
    const metrologiaFrameSuperiorLimSuperior = getFrameMedidas([{ column: 'Medición', value: '0°' }], 'Límite superior (" pulgadas)', metrologiaFrameSuperior);
    informesElements.addTable(
        [
            {
                rows: [
                    [
                        {
                            text: `Metrología pista de rodamiento inferior N° Rod: 8864953P119\n(Rangos: ${metrologiaFrameInferiorLimInferior === 'N/A' ? '36,628' : metrologiaFrameInferiorLimInferior}" min - ${metrologiaFrameInferiorLimSuperior === 'N/A' ? '36,632' : metrologiaFrameInferiorLimSuperior}" max)`,
                            bold: true,
                        }
                    ]
                ],
                rowCellsHeightInUnits: 2,
                rowsColor: informesElements.titleCellsFillColor,
            },
            {
                rows: [
                    [
                        { text: 'Grados', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida A', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida B', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida C', bold: true, color: informesElements.cellsFillColor }
                    ],
                    [
                        { text: '0°', bold: true, color: informesElements.cellsFillColor },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida A' }], '0°', metrologiaFrameInferior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida B' }], '0°', metrologiaFrameInferior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida C' }], '0°', metrologiaFrameInferior) }
                    ],
                    [
                        { text: '45°', bold: true, color: informesElements.cellsFillColor },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida A' }], '45°', metrologiaFrameInferior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida B' }], '45°', metrologiaFrameInferior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida C' }], '45°', metrologiaFrameInferior) }
                    ],
                    [
                        { text: '90°', bold: true, color: informesElements.cellsFillColor },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida A' }], '90°', metrologiaFrameInferior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida B' }], '90°', metrologiaFrameInferior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida C' }], '90°', metrologiaFrameInferior) }
                    ],
                    [
                        { text: '135°', bold: true, color: informesElements.cellsFillColor },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida A' }], '135°', metrologiaFrameInferior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida B' }], '135°', metrologiaFrameInferior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida C' }], '135°', metrologiaFrameInferior) }
                    ],
                    [
                        { text: 'Promedio', bold: true, color: informesElements.cellsFillColor },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida A' }], 'Promedio', metrologiaFrameInferior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida B' }], 'Promedio', metrologiaFrameInferior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida C' }], 'Promedio', metrologiaFrameInferior) }
                    ]
                ]
            }
        ]
    );
    informesElements.addTable(
        [
            {
                rows: [
                    [
                        {
                            text: `Metrología pista de rodamiento superior N° Rod: 8864953P120\n(Rangos: ${metrologiaFrameSuperiorLimInferior === 'N/A' ? '36,553' : metrologiaFrameSuperiorLimInferior}" min - ${metrologiaFrameSuperiorLimSuperior === 'N/A' ? '36,549' : metrologiaFrameSuperiorLimSuperior}" max)`,
                            bold: true,
                        }
                    ]
                ],
                rowCellsHeightInUnits: 2,
                rowsColor: informesElements.titleCellsFillColor,
            },
            {
                rows: [
                    [
                        { text: 'Grados', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida A', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida B', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida C', bold: true, color: informesElements.cellsFillColor }
                    ],
                    [
                        { text: '0°', bold: true, color: informesElements.cellsFillColor },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida A' }], '0°', metrologiaFrameSuperior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida B' }], '0°', metrologiaFrameSuperior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida C' }], '0°', metrologiaFrameSuperior) }
                    ],
                    [
                        { text: '45°', bold: true, color: informesElements.cellsFillColor },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida A' }], '45°', metrologiaFrameSuperior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida B' }], '45°', metrologiaFrameSuperior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida C' }], '45°', metrologiaFrameSuperior) }
                    ],
                    [
                        { text: '90°', bold: true, color: informesElements.cellsFillColor },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida A' }], '90°', metrologiaFrameSuperior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida B' }], '90°', metrologiaFrameSuperior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida C' }], '90°', metrologiaFrameSuperior) }
                    ],
                    [
                        { text: '135°', bold: true, color: informesElements.cellsFillColor },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida A' }], '135°', metrologiaFrameSuperior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida B' }], '135°', metrologiaFrameSuperior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida C' }], '135°', metrologiaFrameSuperior) }
                    ],
                    [
                        { text: 'Promedio', bold: true, color: informesElements.cellsFillColor },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida A' }], 'Promedio', metrologiaFrameSuperior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida B' }], 'Promedio', metrologiaFrameSuperior) },
                        { text: getFrameMedidas([{ column: 'Medición', value: 'Medida C' }], 'Promedio', metrologiaFrameSuperior) }
                    ]
                ]
            }
        ]
    );
    informesElements.rectangleObs(
        'Observaciones metrología pistas superior e inferior de frame',
        metrologiaFrameTarea?.observations ? [metrologiaFrameTarea?.observations] : undefined
    );
    
    informesElements.addPage();
    informesElements.docSubtitleRectangle('METROLOGÍA PISTAS RODAMIENTO RECTO DE FRAME', undefined, true);
    const metrologiaRodRectosTarea = metrologiaPartesMecanicasFrameEtapa?.tareas.find((tarea) => tarea.nombre === 'Metrología alojamientos rod. rectos frame');
    const metrologiaRodRectosA = metrologiaRodRectosTarea?.tables?.find((table) => table.name === 'Metrología pista rodamiento recto A N° Rod: 8864951P206');
    const metrologiaRodRectosB = metrologiaRodRectosTarea?.tables?.find((table) => table.name === 'Metrología pista rodamiento recto B N° Rod: 8864951P206');
    const metrologiaRodRectosC = metrologiaRodRectosTarea?.tables?.find((table) => table.name === 'Metrología pista rodamiento recto C N° Rod: 8864951P206');
    const getRodRectosMedidas = getMedidasMethodInches(metrologiaRodRectosTarea);
    const metrologiaPistaRodRectoALimInferior = getRodRectosMedidas([{ column: 'Medición', value: 'Medida A' }], 'Límite inferior (" pulgadas)', metrologiaRodRectosA);
    const metrologiaPistaRodRectoALimSuperior = getRodRectosMedidas([{ column: 'Medición', value: 'Medida A' }], 'Límite superior (" pulgadas)', metrologiaRodRectosA);
    informesElements.addTable(
        [
            {
                rows: [
                    [
                        {
                            text: `Metrología pista rodamiento recto “A” N° Rod: 8864951P206\n(Rangos: ${metrologiaPistaRodRectoALimInferior === 'N/A' ? '13,3855' : metrologiaPistaRodRectoALimInferior}" min - ${metrologiaPistaRodRectoALimSuperior === 'N/A' ? '13,3869' : metrologiaPistaRodRectoALimSuperior}" max)`,
                            bold: true,
                        }
                    ]
                ],
                rowCellsHeightInUnits: 2,
                rowsColor: informesElements.titleCellsFillColor,
            },
            {
                rows: [
                    [
                        { text: 'Grados', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida A', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida B', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida C', bold: true, color: informesElements.cellsFillColor }
                    ],
                    [
                        { text: '0°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida A' }], '0°', metrologiaRodRectosA) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida B' }], '0°', metrologiaRodRectosA) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida C' }], '0°', metrologiaRodRectosA) }
                    ],
                    [
                        { text: '45°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida A' }], '45°', metrologiaRodRectosA) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida B' }], '45°', metrologiaRodRectosA) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida C' }], '45°', metrologiaRodRectosA) }
                    ],
                    [
                        { text: '90°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida A' }], '90°', metrologiaRodRectosA) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida B' }], '90°', metrologiaRodRectosA) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida C' }], '90°', metrologiaRodRectosA) }
                    ],
                    [
                        { text: '135°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida A' }], '135°', metrologiaRodRectosA) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida B' }], '135°', metrologiaRodRectosA) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida C' }], '135°', metrologiaRodRectosA) }
                    ],
                    [
                        { text: 'Promedio', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida A' }], 'Promedio', metrologiaRodRectosA) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida B' }], 'Promedio', metrologiaRodRectosA) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida C' }], 'Promedio', metrologiaRodRectosA) }
                    ]
                ]
            }
        ]
    );
    informesElements.addTable(
        [
            {
                rows: [
                    [
                        {
                            text: `Metrología pista rodamiento recto “B” N° Rod: 8864951P206\n(Rangos: ${metrologiaPistaRodRectoALimInferior === 'N/A' ? '13,3855' : metrologiaPistaRodRectoALimInferior}" min - ${metrologiaPistaRodRectoALimSuperior === 'N/A' ? '13,3869' : metrologiaPistaRodRectoALimSuperior}" max)`,
                            bold: true,
                        }
                    ]
                ],
                rowCellsHeightInUnits: 2,
                rowsColor: informesElements.titleCellsFillColor,
            },
            {
                rows: [
                    [
                        { text: 'Grados', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida A', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida B', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida C', bold: true, color: informesElements.cellsFillColor }
                    ],
                    [
                        { text: '0°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida A' }], '0°', metrologiaRodRectosB) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida B' }], '0°', metrologiaRodRectosB) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida C' }], '0°', metrologiaRodRectosB) }
                    ],
                    [
                        { text: '45°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida A' }], '45°', metrologiaRodRectosB) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida B' }], '45°', metrologiaRodRectosB) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida C' }], '45°', metrologiaRodRectosB) }
                    ],
                    [
                        { text: '90°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida A' }], '90°', metrologiaRodRectosB) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida B' }], '90°', metrologiaRodRectosB) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida C' }], '90°', metrologiaRodRectosB) }
                    ],
                    [
                        { text: '135°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida A' }], '135°', metrologiaRodRectosB) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida B' }], '135°', metrologiaRodRectosB) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida C' }], '135°', metrologiaRodRectosB) }
                    ],
                    [
                        { text: 'Promedio', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida A' }], 'Promedio', metrologiaRodRectosB) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida B' }], 'Promedio', metrologiaRodRectosB) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida C' }], 'Promedio', metrologiaRodRectosB) }
                    ]
                ]
            }
        ]
    );
    informesElements.addTable(
        [
            {
                rows: [
                    [
                        {
                            text: `Metrología pista rodamiento recto “C” N° Rod: 8864951P206\n(Rangos: ${metrologiaPistaRodRectoALimInferior === 'N/A' ? '13,3855' : metrologiaPistaRodRectoALimInferior}" min - ${metrologiaPistaRodRectoALimSuperior === 'N/A' ? '13,3869' : metrologiaPistaRodRectoALimSuperior}" max)`,
                            bold: true,
                        }
                    ]
                ],
                rowCellsHeightInUnits: 2,
                rowsColor: informesElements.titleCellsFillColor,
            },
            {
                rows: [
                    [
                        { text: 'Grados', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida A', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida B', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida C', bold: true, color: informesElements.cellsFillColor }
                    ],
                    [
                        { text: '0°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida A' }], '0°', metrologiaRodRectosC) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida B' }], '0°', metrologiaRodRectosC) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida C' }], '0°', metrologiaRodRectosC) }
                    ],
                    [
                        { text: '45°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida A' }], '45°', metrologiaRodRectosC) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida B' }], '45°', metrologiaRodRectosC) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida C' }], '45°', metrologiaRodRectosC) }
                    ],
                    [
                        { text: '90°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida A' }], '90°', metrologiaRodRectosC) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida B' }], '90°', metrologiaRodRectosC) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida C' }], '90°', metrologiaRodRectosC) }
                    ],
                    [
                        { text: '135°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida A' }], '135°', metrologiaRodRectosC) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida B' }], '135°', metrologiaRodRectosC) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida C' }], '135°', metrologiaRodRectosC) }
                    ],
                    [
                        { text: 'Promedio', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida A' }], 'Promedio', metrologiaRodRectosC) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida B' }], 'Promedio', metrologiaRodRectosC) },
                        { text: getRodRectosMedidas([{ column: 'Medición', value: 'Medida C' }], 'Promedio', metrologiaRodRectosC) }
                    ]
                ]
            }
        ]
    );
    informesElements.rectangleObs(
        'Observaciones metrología alojamientos rodamientos rectos de frame',
        metrologiaRodRectosTarea?.observations ? [metrologiaRodRectosTarea?.observations] : undefined
    );

    informesElements.addPage();
    informesElements.docSubtitleRectangle('METROLOGÍA PISTAS RODAMIENTO CÓNICO DE FRAME', undefined, true);
    const metrologiaRodConicosTarea = metrologiaPartesMecanicasFrameEtapa?.tareas.find((tarea) => tarea.nombre === 'Metrología alojamientos rod. cónicos frame');
    const metrologiaRodConicosA = metrologiaRodConicosTarea?.tables?.find((table) => table.name === 'Metrología pista rodamiento cónico A N° Rod: 8864961P12');
    const metrologiaRodConicosB = metrologiaRodConicosTarea?.tables?.find((table) => table.name === 'Metrología pista rodamiento cónico B N° Rod: 8864961P12');
    const metrologiaRodConicosC = metrologiaRodConicosTarea?.tables?.find((table) => table.name === 'Metrología pista rodamiento cónico C N° Rod: 8864961P12');
    const getRodConicoMedidas = getMedidasMethodInches(metrologiaRodConicosTarea);
    const metrologiaPistaRodConicoALimInferior = getRodConicoMedidas([{ column: 'Medición', value: 'Medida A' }], 'Límite inferior (" pulgadas)', metrologiaRodConicosA);
    const metrologiaPistaRodConicoALimSuperior = getRodConicoMedidas([{ column: 'Medición', value: 'Medida A' }], 'Límite superior (" pulgadas)', metrologiaRodConicosA);
    informesElements.addTable(
        [
            {
                rows: [
                    [
                        {
                            text: `Metrología pista rodamiento cónico “A” N° Rod: 8864961P12\n(Rangos: ${metrologiaPistaRodConicoALimInferior === 'N/A' ? '5,9061' : metrologiaPistaRodConicoALimInferior}" min - ${metrologiaPistaRodConicoALimSuperior === 'N/A' ? '5,9077' : metrologiaPistaRodConicoALimSuperior}" max)`,
                            bold: true,
                        }
                    ]
                ],
                rowCellsHeightInUnits: 2,
                rowsColor: informesElements.titleCellsFillColor,
            },
            {
                rows: [
                    [
                        { text: 'Grados', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida A', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida B', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida C', bold: true, color: informesElements.cellsFillColor }
                    ],
                    [
                        { text: '0°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida A' }], '0°', metrologiaRodConicosA) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida B' }], '0°', metrologiaRodConicosA) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida C' }], '0°', metrologiaRodConicosA) }
                    ],
                    [
                        { text: '45°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida A' }], '45°', metrologiaRodConicosA) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida B' }], '45°', metrologiaRodConicosA) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida C' }], '45°', metrologiaRodConicosA) }
                    ],
                    [
                        { text: '90°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida A' }], '90°', metrologiaRodConicosA) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida B' }], '90°', metrologiaRodConicosA) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida C' }], '90°', metrologiaRodConicosA) }
                    ],
                    [
                        { text: '135°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida A' }], '135°', metrologiaRodConicosA) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida B' }], '135°', metrologiaRodConicosA) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida C' }], '135°', metrologiaRodConicosA) }
                    ],
                    [
                        { text: 'Promedio', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida A' }], 'Promedio', metrologiaRodConicosA) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida B' }], 'Promedio', metrologiaRodConicosA) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida C' }], 'Promedio', metrologiaRodConicosA) }
                    ]
                ]
            }
        ]
    );
    informesElements.addTable(
        [
            {
                rows: [
                    [
                        {
                            text: `Metrología pista rodamiento cónico “B” N° Rod: 8864961P12\n(Rangos: ${metrologiaPistaRodConicoALimInferior === 'N/A' ? '5,9061' : metrologiaPistaRodConicoALimInferior}" min - ${metrologiaPistaRodConicoALimSuperior === 'N/A' ? '5,9077' : metrologiaPistaRodConicoALimSuperior}" max)`,
                            bold: true,
                        }
                    ]
                ],
                rowCellsHeightInUnits: 2,
                rowsColor: informesElements.titleCellsFillColor,
            },
            {
                rows: [
                    [
                        { text: 'Grados', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida A', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida B', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida C', bold: true, color: informesElements.cellsFillColor }
                    ],
                    [
                        { text: '0°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida A' }], '0°', metrologiaRodConicosB) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida B' }], '0°', metrologiaRodConicosB) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida C' }], '0°', metrologiaRodConicosB) }
                    ],
                    [
                        { text: '45°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida A' }], '45°', metrologiaRodConicosB) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida B' }], '45°', metrologiaRodConicosB) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida C' }], '45°', metrologiaRodConicosB) }
                    ],
                    [
                        { text: '90°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida A' }], '90°', metrologiaRodConicosB) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida B' }], '90°', metrologiaRodConicosB) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida C' }], '90°', metrologiaRodConicosB) }
                    ],
                    [
                        { text: '135°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida A' }], '135°', metrologiaRodConicosB) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida B' }], '135°', metrologiaRodConicosB) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida C' }], '135°', metrologiaRodConicosB) }
                    ],
                    [
                        { text: 'Promedio', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida A' }], 'Promedio', metrologiaRodConicosB) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida B' }], 'Promedio', metrologiaRodConicosB) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida C' }], 'Promedio', metrologiaRodConicosB) }
                    ]
                ]
            }
        ]
    );
    informesElements.addTable(
        [
            {
                rows: [
                    [
                        {
                            text: `Metrología pista rodamiento cónico “C” N° Rod: 8864961P12\n(Rangos: ${metrologiaPistaRodConicoALimInferior === 'N/A' ? '5,9061' : metrologiaPistaRodConicoALimInferior}" min - ${metrologiaPistaRodConicoALimSuperior === 'N/A' ? '5,9077' : metrologiaPistaRodConicoALimSuperior}" max)`,
                            bold: true,
                        }
                    ]
                ],
                rowCellsHeightInUnits: 2,
                rowsColor: informesElements.titleCellsFillColor,
            },
            {
                rows: [
                    [
                        { text: 'Grados', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida A', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida B', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida C', bold: true, color: informesElements.cellsFillColor }
                    ],
                    [
                        { text: '0°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida A' }], '0°', metrologiaRodConicosC) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida B' }], '0°', metrologiaRodConicosC) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida C' }], '0°', metrologiaRodConicosC) }
                    ],
                    [
                        { text: '45°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida A' }], '45°', metrologiaRodConicosC) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida B' }], '45°', metrologiaRodConicosC) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida C' }], '45°', metrologiaRodConicosC) }
                    ],
                    [
                        { text: '90°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida A' }], '90°', metrologiaRodConicosC) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida B' }], '90°', metrologiaRodConicosC) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida C' }], '90°', metrologiaRodConicosC) }
                    ],
                    [
                        { text: '135°', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida A' }], '135°', metrologiaRodConicosC) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida B' }], '135°', metrologiaRodConicosC) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida C' }], '135°', metrologiaRodConicosC) }
                    ],
                    [
                        { text: 'Promedio', bold: true, color: informesElements.cellsFillColor },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida A' }], 'Promedio', metrologiaRodConicosC) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida B' }], 'Promedio', metrologiaRodConicosC) },
                        { text: getRodConicoMedidas([{ column: 'Medición', value: 'Medida C' }], 'Promedio', metrologiaRodConicosC) }
                    ]
                ]
            }
        ]
    );
    informesElements.rectangleObs(
        'Observaciones metrología alojamientos rodamientos cónicos de frame',
        metrologiaRodConicosTarea?.observations ? [metrologiaRodConicosTarea?.observations] : undefined
    );

    // CONTROL DESGASTE CORONA TUBO DE TORQUE
    informesElements.addPage();
    informesElements.docSubtitleRectangle('CONTROL DESGASTE CORONA TUBO DE TORQUE');
    const metrologiaTuboTorqueAnilloFlingerTarea = metrologiaPartesMecanicasFrameEtapa?.tareas.find((tarea) => tarea.nombre === 'Metrología tubo de torque y anillo flinger');
    const coronaTuboTorque = metrologiaTuboTorqueAnilloFlingerTarea?.tables?.find((table) => table.name === 'Control desgaste corona tubo de torque Diámetro PIN: 0,772”');
    const metrologiaTuboTorque = metrologiaTuboTorqueAnilloFlingerTarea?.tables?.find((table) => table.name === 'Metrología tubo de torque');
    const anilloFlinger = metrologiaTuboTorqueAnilloFlingerTarea?.tables?.find((table) => table.name === 'Metrología anillo flinger');
    const interferencia = metrologiaTuboTorqueAnilloFlingerTarea?.tables?.find((table) => table.name === 'Interferencia');
    const getTuboTorqueAnilloFlingerMedidas = getMedidasMethodInches(metrologiaTuboTorqueAnilloFlingerTarea);
    const coronaTuboTorqueLimInferior = getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: '0°' }], 'Límite inferior (" pulgadas)', coronaTuboTorque);
    const coronaTuboTorqueLimSuperior = getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: '0°' }], 'Límite superior (" pulgadas)', coronaTuboTorque);
    const metrologiaTuboTorqueRango = getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Diámetro interior' }], 'Límite superior (" pulgadas)', metrologiaTuboTorque);
    const anilloFlingerLimInferior = getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Diámetro interior' }], 'Límite inferior (" pulgadas)', anilloFlinger);
    const anilloFlingerLimSuperior = getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Diámetro interior' }], 'Límite superior (" pulgadas)', anilloFlinger);
    // const interferenciaLimInferior = getTuboTorqueMedidas([{ column: 'Medición', value: 'Diámetro interior' }], 'Límite inferior (" pulgadas)', interferencia);
    // const interferenciaLimSuperior = getTuboTorqueMedidas([{ column: 'Medición', value: 'Diámetro interior' }], 'Límite superior (" pulgadas)', interferencia);
    informesElements.addImage(
        controlDesgasteCoronaTuboTorque,
        informesElements.cellsHeight * 8,
        informesElements.totalUsableWidth / 2.5,
        informesElements.addTable(
            [
                {
                    rows: [
                        [
                            {
                                text: `Diámetro PIN: 0,772”\n(Rangos: ${coronaTuboTorqueLimInferior === 'N/A' ? '40,070' : coronaTuboTorqueLimInferior}" min - ${coronaTuboTorqueLimSuperior === 'N/A' ? '40,085' : coronaTuboTorqueLimSuperior}" max)`,
                                bold: true,
                            }
                        ]
                    ],
                    rowCellsHeightInUnits: 2,
                    rowsColor: informesElements.titleCellsFillColor,
                },
                {
                    rows: [
                        [
                            { text: 'Grados', bold: true, color: informesElements.cellsFillColor },
                            { text: 'Medida A', bold: true, color: informesElements.cellsFillColor },
                            { text: 'Medida B', bold: true, color: informesElements.cellsFillColor },
                            { text: 'Medida C', bold: true, color: informesElements.cellsFillColor }
                        ],
                        [
                            { text: '0°', bold: true, color: informesElements.cellsFillColor },
                            { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Medida A' }], '0°', coronaTuboTorque) },
                            { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Medida B' }], '0°', coronaTuboTorque) },
                            { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Medida C' }], '0°', coronaTuboTorque) }
                        ],
                        [
                            { text: '45°', bold: true, color: informesElements.cellsFillColor },
                            { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Medida A' }], '45°', coronaTuboTorque) },
                            { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Medida B' }], '45°', coronaTuboTorque) },
                            { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Medida C' }], '45°', coronaTuboTorque) }
                        ],
                        [
                            { text: '90°', bold: true, color: informesElements.cellsFillColor },
                            { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Medida A' }], '90°', coronaTuboTorque) },
                            { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Medida B' }], '90°', coronaTuboTorque) },
                            { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Medida C' }], '90°', coronaTuboTorque) }
                        ],
                        [
                            { text: '135°', bold: true, color: informesElements.cellsFillColor },
                            { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Medida A' }], '135°', coronaTuboTorque) },
                            { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Medida B' }], '135°', coronaTuboTorque) },
                            { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Medida C' }], '135°', coronaTuboTorque) }
                        ],
                        [
                            { text: 'Promedio', bold: true, color: informesElements.cellsFillColor },
                            { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Medida A' }], 'Promedio', coronaTuboTorque) },
                            { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Medida B' }], 'Promedio', coronaTuboTorque) },
                            { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Medida C' }], 'Promedio', coronaTuboTorque) }
                        ]
                    ]
                }
            ],
            halfWidth,
            true
        ),
        undefined,
        true
    );

    informesElements.docSubtitleRectangle('CONTROL INTERFERENCIA TUBO DE TORQUE Y ANILLO FLINGER', undefined, true);
    informesElements.addTable(
        [
            {
                rows: [
                    [
                        {
                            text: `Metrología tubo de torque\n(Rango: ${metrologiaTuboTorqueRango === 'N/A' ? '45,125"' : metrologiaTuboTorqueRango})`,
                            bold: true,
                        }
                    ]
                ],
                rowCellsHeightInUnits: 2,
                rowsColor: informesElements.titleCellsFillColor,
            },
            {
                rows: [
                    [
                        { text: 'Medición', bold: true, color: informesElements.cellsFillColor, cellsWidthInUnits: 2 },
                        { text: '0°', bold: true, color: informesElements.cellsFillColor },
                        { text: '30°', bold: true, color: informesElements.cellsFillColor },
                        { text: '60°', bold: true, color: informesElements.cellsFillColor },
                        { text: '90°', bold: true, color: informesElements.cellsFillColor },
                        { text: '120°', bold: true, color: informesElements.cellsFillColor },
                        { text: '150°', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Promedio', bold: true, color: informesElements.cellsFillColor, cellsWidthInUnits: 2 },
                    ],
                    [
                        { text: 'Diámetro interior', bold: true, color: informesElements.cellsFillColor, cellsWidthInUnits: 2 },
                        { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Diámetro interior' }], '0°', metrologiaTuboTorque) },
                        { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Diámetro interior' }], '30°', metrologiaTuboTorque) },
                        { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Diámetro interior' }], '60°', metrologiaTuboTorque) },
                        { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Diámetro interior' }], '90°', metrologiaTuboTorque) },
                        { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Diámetro interior' }], '120°', metrologiaTuboTorque) },
                        { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Diámetro interior' }], '150°', metrologiaTuboTorque) },
                        { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Diámetro interior' }], 'Promedio', metrologiaTuboTorque), cellsWidthInUnits: 2 }
                    ]
                ],
                totalCellsWidthInUnits: 10
            }
        ]
    );
    informesElements.addTable(
        [
            {
                rows: [
                    [
                        {
                            text: `Metrología anillo flinger\n(Rangos: ${anilloFlingerLimInferior === 'N/A' ? '44,103"' : anilloFlingerLimInferior} min - ${anilloFlingerLimSuperior === 'N/A' ? '44,106"' : anilloFlingerLimSuperior} max)`,
                            bold: true,
                        }
                    ]
                ],
                rowCellsHeightInUnits: 2,
                rowsColor: informesElements.titleCellsFillColor,
            },
            {
                rows: [
                    [
                        { text: 'Medición', bold: true, color: informesElements.cellsFillColor, cellsWidthInUnits: 2 },
                        { text: '0°', bold: true, color: informesElements.cellsFillColor },
                        { text: '30°', bold: true, color: informesElements.cellsFillColor },
                        { text: '60°', bold: true, color: informesElements.cellsFillColor },
                        { text: '90°', bold: true, color: informesElements.cellsFillColor },
                        { text: '120°', bold: true, color: informesElements.cellsFillColor },
                        { text: '150°', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Promedio', bold: true, color: informesElements.cellsFillColor, cellsWidthInUnits: 2 },
                    ],
                    [
                        { text: 'Diámetro interior', bold: true, color: informesElements.cellsFillColor, cellsWidthInUnits: 2 },
                        { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Diámetro interior' }], '0°', anilloFlinger) },
                        { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Diámetro interior' }], '30°', anilloFlinger) },
                        { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Diámetro interior' }], '60°', anilloFlinger) },
                        { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Diámetro interior' }], '90°', anilloFlinger) },
                        { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Diámetro interior' }], '120°', anilloFlinger) },
                        { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Diámetro interior' }], '150°', anilloFlinger) },
                        { text: getTuboTorqueAnilloFlingerMedidas([{ column: 'Medición', value: 'Diámetro interior' }], 'Promedio', anilloFlinger), cellsWidthInUnits: 2 }
                    ]
                ],
                totalCellsWidthInUnits: 10
            }
        ]
    );
    informesElements.addTable([{
        rows: [
            [
                { text: 'Interferencia', bold: true, color: informesElements.titleCellsFillColor },
                { text: 'Promedio', bold: true, color: informesElements.titleCellsFillColor }
            ],
            [
                {
                    text: `Rango: ${interferencia?.rows[0][2].content[0] || '0,019'}" - ${interferencia?.rows[0][3].content[0] || '0,027'}"`,
                    bold: true,
                    color: informesElements.titleCellsFillColor
                },
                { text: metrologiaTuboTorqueAnilloFlingerTarea?.applyElements.apply && interferencia?.rows[0][0].apply ? interferencia.rows[0][1].content[0] : 'N/A' }
            ]
        ]
    }]);
    informesElements.rectangleObs(
        'Observaciones metrología tubo de torque',
        metrologiaTuboTorqueAnilloFlingerTarea?.observations ? [metrologiaTuboTorqueAnilloFlingerTarea?.observations] : undefined
    );

    informesElements.addPage();
    // const controlDesgasteCoronaTuboTorque2Width = ;
    const controlDesgasteCoronaTuboTorque3Height = informesElements.cellsHeight * 10;
    informesElements.addImage(
        controlDesgasteCoronaTuboTorque3,
        controlDesgasteCoronaTuboTorque3Height,
        undefined,
        informesElements.addImage(
            controlDesgasteCoronaTuboTorque2,
            controlDesgasteCoronaTuboTorque3Height,
            informesElements.totalUsableWidth / 2.5,
            undefined,
            undefined,
            false,
            false,
            true,
            true
        )
    );
    // CONTROL DESGASTE PIÑÓN SOLAR
    informesElements.docSubtitleRectangle('CONTROL DESGASTE PIÑÓN SOLAR', undefined, true);
    const metrologiaPinonSolarTarea = metrologiaPartesMecanicasFrameEtapa?.tareas.find((tarea) => tarea.nombre === 'Metrología a piñón solar');
    const desgasteSpline = metrologiaPinonSolarTarea?.tables?.find((table) => table.name === 'Desgaste de spline PIN: 0.240"');
    const desgasteEngranaje = metrologiaPinonSolarTarea?.tables?.find((table) => table.name === 'Desgaste de engranaje PIN: 0.576"');
    const getDesgasteSplineMedidas = getMedidasMethodInches(metrologiaPinonSolarTarea);
    const desgasteSplineLimInferior = getDesgasteSplineMedidas([{ column: 'Medición', value: '0°' }], 'Límite inferior (" pulgadas)', desgasteSpline);
    const desgasteSplineLimSuperior = getDesgasteSplineMedidas([{ column: 'Medición', value: '0°' }], 'Límite superior (" pulgadas)', desgasteSpline);
    const desgasteEngranajeLimInferior = getDesgasteSplineMedidas([{ column: 'Medición', value: '0°' }], 'Límite inferior (" pulgadas)', desgasteEngranaje);
    const desgasteEngranajeLimSuperior = getDesgasteSplineMedidas([{ column: 'Medición', value: '0°' }], 'Límite superior (" pulgadas)', desgasteEngranaje);
    informesElements.addTable(
        [
            {
                rows: [
                    [
                        {
                            text: `Desgaste de engranaje\n(Rangos: ${desgasteEngranajeLimInferior === 'N/A' ? '5,9892' : desgasteEngranajeLimInferior}" min - ${desgasteEngranajeLimSuperior === 'N/A' ? '5,9942' : desgasteEngranajeLimSuperior}" max - PIN 0.576”)`,
                            bold: true,
                        }
                    ]
                ],
                rowCellsHeightInUnits: 2,
                rowsColor: informesElements.titleCellsFillColor,
            },
            {
                rows: [
                    [
                        { text: 'Grados', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida A', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida B', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Medida C', bold: true, color: informesElements.cellsFillColor }
                    ],
                    [
                        { text: '0°', bold: true, color: informesElements.cellsFillColor },
                        { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida A' }], '0°', desgasteEngranaje) },
                        { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida B' }], '0°', desgasteEngranaje) },
                        { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida C' }], '0°', desgasteEngranaje) }
                    ],
                    [
                        { text: '45°', bold: true, color: informesElements.cellsFillColor },
                        { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida A' }], '45°', desgasteEngranaje) },
                        { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida B' }], '45°', desgasteEngranaje) },
                        { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida C' }], '45°', desgasteEngranaje) }
                    ],
                    [
                        { text: '90°', bold: true, color: informesElements.cellsFillColor },
                        { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida A' }], '90°', desgasteEngranaje) },
                        { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida B' }], '90°', desgasteEngranaje) },
                        { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida C' }], '90°', desgasteEngranaje) }
                    ],
                    [
                        { text: '135°', bold: true, color: informesElements.cellsFillColor },
                        { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida A' }], '135°', desgasteEngranaje) },
                        { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida B' }], '135°', desgasteEngranaje) },
                        { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida C' }], '135°', desgasteEngranaje) }
                    ],
                    [
                        { text: 'Promedio', bold: true, color: informesElements.cellsFillColor },
                        { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida A' }], 'Promedio', desgasteEngranaje) },
                        { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida B' }], 'Promedio', desgasteEngranaje) },
                        { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida C' }], 'Promedio', desgasteEngranaje) }
                    ]
                ]
            }
        ],
        undefined,
        false,
        informesElements.addTable(
            [
                {
                    rows: [
                        [
                            {
                                text: `Desgaste de spline \n(Rangos: ${desgasteSplineLimInferior === 'N/A' ? '4,716' : desgasteSplineLimInferior}" min - ${desgasteSplineLimSuperior === 'N/A' ? '4,720' : desgasteSplineLimSuperior}" max - PIN 0,240”)`,
                                bold: true,
                            }
                        ]
                    ],
                    rowCellsHeightInUnits: 2,
                    rowsColor: informesElements.titleCellsFillColor,
                },
                {
                    rows: [
                        [
                            { text: 'Grados', bold: true, color: informesElements.cellsFillColor },
                            { text: 'Medida A', bold: true, color: informesElements.cellsFillColor },
                            { text: 'Medida B', bold: true, color: informesElements.cellsFillColor },
                            { text: 'Medida C', bold: true, color: informesElements.cellsFillColor }
                        ],
                        [
                            { text: '0°', bold: true, color: informesElements.cellsFillColor },
                            { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida A' }], '0°', desgasteSpline) },
                            { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida B' }], '0°', desgasteSpline) },
                            { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida C' }], '0°', desgasteSpline) }
                        ],
                        [
                            { text: '45°', bold: true, color: informesElements.cellsFillColor },
                            { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida A' }], '45°', desgasteSpline) },
                            { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida B' }], '45°', desgasteSpline) },
                            { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida C' }], '45°', desgasteSpline) }
                        ],
                        [
                            { text: '90°', bold: true, color: informesElements.cellsFillColor },
                            { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida A' }], '90°', desgasteSpline) },
                            { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida B' }], '90°', desgasteSpline) },
                            { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida C' }], '90°', desgasteSpline) }
                        ],
                        [
                            { text: '135°', bold: true, color: informesElements.cellsFillColor },
                            { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida A' }], '135°', desgasteSpline) },
                            { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida B' }], '135°', desgasteSpline) },
                            { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida C' }], '135°', desgasteSpline) }
                        ],
                        [
                            { text: 'Promedio', bold: true, color: informesElements.cellsFillColor },
                            { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida A' }], 'Promedio', desgasteSpline) },
                            { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida B' }], 'Promedio', desgasteSpline) },
                            { text: getDesgasteSplineMedidas([{ column: 'Medición', value: 'Medida C' }], 'Promedio', desgasteSpline) }
                        ]
                    ]
                }
            ],
            halfWidth,
            true
        )
    );
    informesElements.addImage(
        controlDesgastePinonSolar,
        informesElements.cellsHeight * 6,
        informesElements.totalUsableWidth / 3,
        undefined,
        undefined,
        true
    );
    informesElements.rectangleObs(
        'Observaciones metrología a piñón solar',
        metrologiaPinonSolarTarea?.observations ? [metrologiaPinonSolarTarea?.observations] : undefined
    );

    informesElements.addPage();
    informesElements.docSubtitleRectangle('METROLOGÍA CUBO DE RUEDA');
    const medicionCuboRuedaTarea = metrologiaPartesMecanicasFrameEtapa?.tareas.find((tarea) => tarea.nombre === 'Medición cubo de rueda');
    const metrologiaCuboRueda = medicionCuboRuedaTarea?.tables?.find((table) => table.name === 'Metrología cubo de rueda');
    const metrologiaAnguloCono = medicionCuboRuedaTarea?.tables?.find((table) => table.name === 'Ángulo del cono');
    const getMetrologiaCuboRuedaMedidas = getMedidasMethodInches(medicionCuboRuedaTarea);
    const getMetrologiaAnguloConoMedidas = (columnsNameWithValue: ColumnWithValue[], targetColumnName: string) => getTableCellValueWithUnits(columnsNameWithValue, targetColumnName, medicionCuboRuedaTarea?.applyElements.apply, metrologiaAnguloCono, '°');
    const d1LimInferior = getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático recto (D1)' }], 'Límite inferior (" pulgadas)', metrologiaCuboRueda);
    const d1imSuperior = getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático recto (D1)' }], 'Límite superior (" pulgadas)', metrologiaCuboRueda);
    const d2LimInferior = getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático cónico (D2)' }], 'Límite inferior (" pulgadas)', metrologiaCuboRueda);
    const d2imSuperior = getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático cónico (D2)' }], 'Límite superior (" pulgadas)', metrologiaCuboRueda);
    const d3LimInferior = getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático cónico (D3)' }], 'Límite inferior (" pulgadas)', metrologiaCuboRueda);
    const d3imSuperior = getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático cónico (D3)' }], 'Límite superior (" pulgadas)', metrologiaCuboRueda);
    informesElements.addTable(
        [
            {
                rows: [[
                    { text: 'Medida cubo de rueda', bold: true, cellsWidthInUnits: 6 },
                    { text: 'Medida', bold: true, cellsWidthInUnits: 8 }
                ]],
                rowsColor: informesElements.titleCellsFillColor,
                totalCellsWidthInUnits: 14,
            },
            {
                rows: [
                    [
                        { text: 'Medida inicial\ncubo de rueda', bold: true, cellsWidthInUnits: 3, color: informesElements.cellsFillColor },
                        { text: 'Rango', bold: true, cellsWidthInUnits: 3, color: informesElements.cellsFillColor },
                        { text: '0°', bold: true, color: informesElements.cellsFillColor },
                        { text: '30°', bold: true, color: informesElements.cellsFillColor },
                        { text: '60°', bold: true, color: informesElements.cellsFillColor },
                        { text: '90°', bold: true, color: informesElements.cellsFillColor },
                        { text: '120°', bold: true, color: informesElements.cellsFillColor },
                        { text: '150°', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Promedio', bold: true, cellsWidthInUnits: 2, color: informesElements.cellsFillColor },
                    ],
                    [
                        { text: 'Asiento neumático\nrecto (D1)', bold: true, cellsWidthInUnits: 3, color: informesElements.cellsFillColor },
                        {
                            text: `${d1LimInferior === 'N/A' ? '51,100' : d1LimInferior}" min -\n${d1imSuperior === 'N/A' ? '51,140' : d1imSuperior}" max`,
                            bold: true, cellsWidthInUnits: 3,
                            color: informesElements.cellsFillColor
                        },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático recto (D1)' }], '0°', metrologiaCuboRueda) },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático recto (D1)' }], '30°', metrologiaCuboRueda) },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático recto (D1)' }], '60°', metrologiaCuboRueda) },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático recto (D1)' }], '90°', metrologiaCuboRueda) },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático recto (D1)' }], '120°', metrologiaCuboRueda) },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático recto (D1)' }], '150°', metrologiaCuboRueda) },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático recto (D1)' }], 'Promedio', metrologiaCuboRueda), cellsWidthInUnits: 2 }
                    ],
                    [
                        { text: 'Asiento neumático\ncónico (D2)', bold: true, cellsWidthInUnits: 3, color: informesElements.cellsFillColor },
                        {
                            text: `${d2LimInferior === 'N/A' ? '51,100' : d2LimInferior}" min -\n${d2imSuperior === 'N/A' ? '51,140' : d2imSuperior}" max`,
                            bold: true, cellsWidthInUnits: 3,
                            color: informesElements.cellsFillColor
                        },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático cónico (D2)' }], '0°', metrologiaCuboRueda) },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático cónico (D2)' }], '30°', metrologiaCuboRueda) },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático cónico (D2)' }], '60°', metrologiaCuboRueda) },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático cónico (D2)' }], '90°', metrologiaCuboRueda) },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático cónico (D2)' }], '120°', metrologiaCuboRueda) },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático cónico (D2)' }], '150°', metrologiaCuboRueda) },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático cónico (D2)' }], 'Promedio', metrologiaCuboRueda), cellsWidthInUnits: 2 }
                    ],
                    [
                        { text: 'Asiento neumático\ncónico (D3)', bold: true, cellsWidthInUnits: 3, color: informesElements.cellsFillColor },
                        {
                            text: `${d3LimInferior === 'N/A' ? '52,720' : d3LimInferior}" min -\n${d3imSuperior === 'N/A' ? '52,750' : d3imSuperior}" max`,
                            bold: true, cellsWidthInUnits: 3,
                            color: informesElements.cellsFillColor
                        },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático cónico (D3)' }], '0°', metrologiaCuboRueda) },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático cónico (D3)' }], '30°', metrologiaCuboRueda) },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático cónico (D3)' }], '60°', metrologiaCuboRueda) },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático cónico (D3)' }], '90°', metrologiaCuboRueda) },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático cónico (D3)' }], '120°', metrologiaCuboRueda) },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático cónico (D3)' }], '150°', metrologiaCuboRueda) },
                        { text: getMetrologiaCuboRuedaMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Asiento neumático cónico (D3)' }], 'Promedio', metrologiaCuboRueda), cellsWidthInUnits: 2 }
                    ],
                    [
                        { text: 'Ángulo de cono', bold: true, cellsWidthInUnits: 3, color: informesElements.cellsFillColor },
                        {
                            text: '28°',
                            bold: true, cellsWidthInUnits: 3,
                            color: informesElements.cellsFillColor
                        },
                        { text: getMetrologiaAnguloConoMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Ángulo del cono' }], '0°') },
                        { text: getMetrologiaAnguloConoMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Ángulo del cono' }], '30°') },
                        { text: getMetrologiaAnguloConoMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Ángulo del cono' }], '60°') },
                        { text: getMetrologiaAnguloConoMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Ángulo del cono' }], '90°') },
                        { text: getMetrologiaAnguloConoMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Ángulo del cono' }], '120°') },
                        { text: getMetrologiaAnguloConoMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Ángulo del cono' }], '150°') },
                        { text: getMetrologiaAnguloConoMedidas([{ column: 'Medida inicial cubo de rueda', value: 'Ángulo del cono' }], 'Promedio'), cellsWidthInUnits: 2 }
                    ],
                ],
                totalCellsWidthInUnits: 14,
                rowCellsHeightInUnits: 2
            },
        ]
    );
    informesElements.addImage(
        metrologiaCuboRuedas,
        informesElements.cellsHeight * 12,
        informesElements.totalUsableWidth - (informesElements.margin * 2),
        undefined,
        undefined,
        true
    );
    informesElements.addImage(
        metrologiaCuboRuedas2,
        informesElements.cellsHeight * 12,
        informesElements.totalUsableWidth - (informesElements.margin * 4),
        undefined,
        undefined,
        true,
        true
    );

    // METROLOGÍA ANILLO FLINGER
    const medidasAnilloFlingerTable = metrologiaTuboTorqueAnilloFlingerTarea?.tables?.find((table) => table.name === 'Medidas anillo flinger');
    const getMedidasAnilloFlinger = getMedidasMethodInches(metrologiaTuboTorqueAnilloFlingerTarea, medidasAnilloFlingerTable);
    informesElements.addPage();
    informesElements.docSubtitleRectangle('METROLOGÍA ANILLO FLINGER');
    informesElements.addTable(
        [
            {
                rows: [[{ text: 'Medidas anillo flinger', bold: true }]],
                rowsColor: informesElements.titleCellsFillColor
            },
            {
                rows: [
                    [
                        { text: 'Sección medida', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Valor referencial', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Valor medido', bold: true, color: informesElements.cellsFillColor },
                        { text: 'Resultado', bold: true, color: informesElements.cellsFillColor }
                    ],
                    [
                        { text: 'A', bold: true },
                        { text: getMedidasAnilloFlinger([{ column: 'Sección medida', value: 'A' }], 'Límite inferior (" pulgadas)') },
                        { text: getMedidasAnilloFlinger([{ column: 'Sección medida', value: 'A' }], 'Valor medido') },
                        { text: getMedidasAnilloFlinger([{ column: 'Sección medida', value: 'A' }], 'Resultado', undefined, true) },
                    ],
                    [
                        { text: 'B', bold: true },
                        { text: getMedidasAnilloFlinger([{ column: 'Sección medida', value: 'B' }], 'Límite inferior (" pulgadas)') },
                        { text: getMedidasAnilloFlinger([{ column: 'Sección medida', value: 'B' }], 'Valor medido') },
                        { text: getMedidasAnilloFlinger([{ column: 'Sección medida', value: 'B' }], 'Resultado', undefined, true) },
                    ],
                    [
                        { text: 'C', bold: true },
                        { text: getMedidasAnilloFlinger([{ column: 'Sección medida', value: 'C' }], 'Límite inferior (" pulgadas)') },
                        { text: getMedidasAnilloFlinger([{ column: 'Sección medida', value: 'C' }], 'Valor medido') },
                        { text: getMedidasAnilloFlinger([{ column: 'Sección medida', value: 'C' }], 'Resultado', undefined, true) },
                    ]
                ]
            },
        ]
    );
    informesElements.addImage(
        esquemasMedidasAnilloFlinger,
        esquemasMedidasHeight,
        anilloFlingerImagesWidth,
        undefined,
        undefined,
        true
    );

    // NIVELES DE REPARACIÓN
    const hiddenValues = finalEtapa.tareas[finalEtapa.tareas.length - 1].hiddenValues;
    if (hiddenValues) {
        // informesElements.addPage();
        informesElements.docSubtitleRectangle('NIVELES DE REPARACIÓN');
        informesElements.addTable(
            [
                {
                    rows: [[
                        {
                            text: 'ELEMENTO',
                            bold: true,
                            // cellsWidthInUnits: 3
                        },
                        {
                            text: 'NIVEL DE REPARACIÓN',
                            bold: true,
                            // cellsWidthInUnits: 3
                        }
                    ]],
                    rowCellsHeightInUnits: 2,
                    rowsColor: informesElements.greyColor,
                    // totalCellsWidthInUnits: 6,
                },
                {
                    rows: hiddenValues.map((hiddenValue) => [
                        { text: `BRIDA ${hiddenValue.nombre}` },
                        { text: `REPARACIÓN: ${hiddenValue.valor}`, bold: true, color: hiddenValue.valor === 'MAYOR' ? informesElements.redColor : hiddenValue.valor === 'MEDIA' ? informesElements.yellowColor : informesElements.green2Color },
                    ])
                }
            ]
        );
    }

    informesElements.checkTablesPages();
    informesElements.conclusionesGenerales();
    informesElements.addPhotosToDoc();
    informesElements.approvingTable(isPreview);

    informesElements.addHeaders(informeTitle, 'ELE-FR/SGC-026');
    informesElements.saveDocument();
}

export default informeEvaluacion5GEB25;